import React from "react";
import axios from "axios";
import Wheel from "./Wheel";

class WheelList extends React.Component {
    state = { chassis: null, wheels: [], filters: [], selectedWheel: null };

    componentDidUpdate() {
        if(this.props.chassis_id !== this.state.chassis){
            this.setState({ chassis: this.props.chassis_id, wheels: [], filters: [], selectedWheel: null
            });
            console.log('Updated state chassis to ' + this.props.chassis_id);
            this.getWheels(this.props.chassis_id);
        }
    }

    async getWheels(chassis_id) {
        const response = await axios.get('https://4x4tyres.co.uk/api/v2/pb_get_wheels', {
            params: {
                id: chassis_id
            }
        });
        this.setState({wheels: response.data.results});
    }

    onWheelSelect = (data) => {
        this.props.onSelect(data);
        this.setState({ selectedWheel: data.id });
    }

    onFilter = (event) => {
        let filters = this.state.filters;
        if(event.target.checked){
            //Add the item
            filters.push(event.target.value);
        }else{
            //Remove the item
            filters = filters.filter(f => f !== event.target.value);
        }
        console.log(filters);
        this.setState({filters: filters});
    }

    wheels() {
        return this.state.wheels.map((wheel) => {
            return (
                this.filterWheel(wheel)
            )
        });
    }


    filterWheel(wheel) {
        console.log('filters');
        console.log(this.state.filters);
        if(this.state.filters.length){
            if(this.state.filters.indexOf(wheel.details.wheel_size)!==-1){
                return (
                    <Wheel
                        key={wheel.id}
                        wheelData={wheel}
                        wheelID={wheel.id}
                        onSelect={this.onWheelSelect}
                        selectedWheel={this.state.selectedWheel}
                    />
                )
            }
        }else{
            return (
                <Wheel
                    key={wheel.id}
                    wheelData={wheel}
                    wheelID={wheel.id}
                    onSelect={this.onWheelSelect}
                    selectedWheel={this.state.selectedWheel}
                />
            )
        }
    }

    wheelsSizeFilter() {
        const unique_sizes = [...new Set(this.state.wheels.map(item => item.details.wheel_size))];
        unique_sizes.sort();
        return (
            <div className="pb-wheel-size-filter mb-3">
                <h5>Filter wheels by size:</h5>
                <div>
                    {this.checkboxes(unique_sizes, 'size-filter')}
                </div>
            </div>
        )
    }

    checkboxes(sizes, ele) {
        return sizes.map((size, index) => {
            return (
                <div key={index} className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name={ele} value={size} id={ele + '-' + index} onChange={this.onFilter}/>
                    <label className="form-check-label" htmlFor={ele + '-' + index}>{size}</label>
                </div>
            )
        });
    }

    chassis_info() {
        return (
            <div>
                <h5>Vehicle info...</h5>
                <div className="pb-info p-2 mb-4 small">
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            PCD
                        </span>
                        <span>
                            {this.props.chassis_info.pcd}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            Centre Bore
                        </span>
                        <span>
                            {this.props.chassis_info.centre_bore}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            Nut or Bolt
                        </span>
                        <span>
                            {this.props.chassis_info.nut_or_bolt}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            Torque
                        </span>
                        <span>
                            {this.props.chassis_info.nut_bolt_torque}
                        </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bolder">
                            Nutbolt Thread type
                        </span>
                        <span>
                            {this.props.chassis_info.nutBolt_thread_type}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    renderWheels() {
        if(this.state.chassis){
            if(this.state.wheels.length){
                return (
                    <div>
                        {this.chassis_info()}
                        {this.wheelsSizeFilter()}
                        <div className="pb-wheel-list mb-5">
                            {this.wheels()}
                        </div>
                    </div>
                )
            }else{
                return (
                    <div className="d-flex align-items-center">
                        <div className="spinner-border spinner-border-sm text-secondary mr-2" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div>
                            Loading wheels...
                        </div>
                    </div>
                )
            }
        }else{
            return (
                <div>Nothing to show yet</div>
            )
        }
    }

    render() {
        return (
            this.renderWheels()
        )
    }
}

export default WheelList;

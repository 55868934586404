import React from "react";
import Accessory from "./Accessory";
import axios from "axios";

class Accessories extends React.Component {
    state = { tyre_size_index: null, accessories: [] };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.tyreSizeIndex !== this.state.tyre_size_index){
            this.setState({tyre_size_index: this.props.tyreSizeIndex, accessories: []});
            this.get_accessories();
        }
    }

    onAccessorySelect = (data) => {
        this.props.onSelect(data);
    }

    onAccessoryRemove = (data) => {
        this.props.onRemove(data);
    }

    async get_accessories() {
        const response = await axios.get('https://4x4tyres.co.uk/api/v2/pb_get_accessories', {
            params: {
                wheel_id: this.props.wheelID,
                chassis: this.props.chassis
            }
        });
        this.setState({ accessories: response.data.results });
    }

    accessory_block(block) {
        if(block.items){
            return (
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">{block.title}</h5>
                    </div>
                </div>
            )
        }
    }

    render_accessories() {
        // eslint-disable-next-line array-callback-return
        return this.state.accessories.map((accessory_type, index) => {
            if(accessory_type.items){
                return (
                    <div className="card mb-3" key={index}>
                        <div className="card-body">
                            <h5 className="card-title mb-4">{accessory_type.title}</h5>
                            {this.render_accessory_items(accessory_type.items)}
                        </div>
                    </div>
                )
            }
        });
    }

    is_accessory_selected(id) {
        return !this.props.selectedAccessories.find(o => o.id === id);
    }

    render_accessory_items(items) {
        return items.map((item) => {
            return (
                <Accessory
                    key={item.id}
                    itemData={item}
                    onSelect={this.onAccessorySelect}
                    onRemove={this.onAccessoryRemove}
                    selected={this.is_accessory_selected(item.id)}/>
            )
        })
    }

    title() {
        let count = 0;
        // eslint-disable-next-line array-callback-return
        this.state.accessories.map(o => {
            if(o.items){
                count+=o.items.length;
            }
        });
        if(count){
            return (
                <h5>Select accessories...</h5>
            )
        }
    }

    accessories() {
        if(this.props.tyreSizeIndex){
            if(this.state.accessories.length){
                return (
                    <div>
                        {this.title()}
                        {this.render_accessories()}
                    </div>
                )
            }else{
                return (
                    <div className="d-flex align-items-center">
                        <div className="spinner-border spinner-border-sm text-secondary mr-2" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div>
                            Loading accessories...
                        </div>
                    </div>
                )
            }
        }
    }

    render() {
        return (
            <div id={this.props.id} className="mb-5">
                {this.accessories()}
            </div>
        )
    }
}

export default Accessories;

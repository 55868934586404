import React from "react";
// get our fontawesome imports
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DownloadImage from "./DownloadImage";

class Wheel extends React.Component {
    onSelect = (event) => {
        this.props.onSelect(this.props.wheelData);
    }

    get_brand() {
        if(this.props.wheelData.brand.logo){
            return (
                <div className="pb-brand">
                    <img src={this.props.wheelData.brand.logo} alt={this.props.wheelData.brand.name} className="w-100 img-fluid"/>
                </div>
            )
        }else{
            return (
                <div className="pb-brand text">
                    <p className="text-center font-weight-bolder">{this.props.wheelData.brand.name}</p>
                </div>
            )
        }
    }

    get_info() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        return (
            <div className="pb-info p-2 mb-2 small">
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Brand name
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.brand.name)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Product type
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.product_type)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Colour
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.color)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Weight
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.weight)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Wheel size
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.wheel_size)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Wheel width
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.wheel_width)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Load rating
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.load_rating)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Offset
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.offset)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        PCD
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.pcd)}
                </div>
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        SKU
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.sku)}
                </div>
                {this.ean()}
                {this.centre_bore()}
            </div>
        )
    }

    ean() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        if(this.props.wheelData.details.ean){
            return (
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        EAN
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.ean)}
                </div>
            )
        }
    }

    centre_bore() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        if(this.props.wheelData.details.centre_bore && this.props.wheelData.details.centre_bore !== '0.0000'){
            return (
                <div className="d-flex justify-content-between">
                    <p className="m-0">
                        Centre bore
                    </p>
                    {renderHTML('p', ['m-0'], this.props.wheelData.details.centre_bore)}
                </div>
            )
        }
    }

    get_stock() {
        return (
            <div>
                <p className="h6 text-center text-primary mt-4 mb-0">{this.props.wheelData.stock} in stock</p>
            </div>
        )
    }

    get_prices() {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        return (
            <div>
                {renderHTML('p', ['h5 m-0 text-uppercase'], 'Price EXCL. VAT: ' + this.props.wheelData.currency + ' ' + this.props.wheelData.price_exc)}
                {renderHTML('p', ['h5 font-weight-bolder text-uppercase'], 'Price INC. VAT: ' + this.props.wheelData.currency + ' ' + this.props.wheelData.price)}
            </div>
        )
    }

    render () {
        const renderHTML = (elem, classes, rawHTML) => React.createElement(elem, { className: classes, dangerouslySetInnerHTML: { __html: rawHTML }} );
        const border = this.props.wheelID === this.props.selectedWheel ? 'border-primary' : '';
        return (
            <div className={`card mb-3 shadow-sm ${border}`} >
                <div className="row no-gutters">
                    <div className="col-12 col-md-4 col-lg-3">
                        <div className="card-body">
                            <DownloadImage imageLarge={this.props.wheelData.image_lg} imageSmall={this.props.wheelData.image}/>
                            {this.get_brand()}
                            {this.get_stock()}
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-9">
                        <div className="card-body">
                            {renderHTML('h5', ['card-title'], this.props.wheelData.name)}

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    {this.get_info()}
                                </div>
                                <div className="col-12 col-lg-6">
                                    {this.get_prices()}
                                    <button className="btn btn-primary" onClick={this.onSelect} value={this.props.wheelData.id}>Choose this wheel</button>
                                    <p className="mt-2">
                                        <FontAwesomeIcon icon={faCheck} className="text-primary" /> Note: if a centre bore is greater than that of the hub, this is perfectly acceptable when using nuts to secure wheels. This is called 'nut centric'.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Wheel;
